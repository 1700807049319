// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  assesment: getIcon('ic_assesment'),
  exercise: getIcon('ic_exercise'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  doctor: getIcon('ic_doctor'),
  dashboard: getIcon('ic_dashboard'),
  diet: getIcon('ic_diet'),
  religion: getIcon('ic_religion'),
  medicine: getIcon('ic_medicine'),
  package: getIcon('voucher')

};

const navConfig = [
  // ----------------------------------------------------------------------
  {
    items: [
      {
        title: 'dashboard',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },

      {
        title: 'user list',
        path: PATH_DASHBOARD.user.list,
        icon: ICONS.calendar,
      },
      {
        title: 'Food',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.invoice,
        children: [
          { title: 'List', path: PATH_DASHBOARD.food.list },
          { title: 'Create', path: PATH_DASHBOARD.food.new },
          { title: 'Add Category', path: PATH_DASHBOARD.food.category },
          { title: 'Add Variant', path: PATH_DASHBOARD.food.varient },


        ],
      },
      {
        title: 'Exercise',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.exercise,

        children: [
          { title: 'list', path: PATH_DASHBOARD.exercise.list },
          { title: 'create', path: PATH_DASHBOARD.exercise.new },
          // { title: 'Add Category', path: PATH_DASHBOARD.exercise.category },
        ],
      },
      {
        title: 'Diet',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.diet,

        children: [
          { title: 'list', path: PATH_DASHBOARD.diet.list },
          { title: 'create', path: PATH_DASHBOARD.diet.new },
        ],
      },
      {
        title: 'Religious',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.religion,

        children: [
          { title: 'list', path: PATH_DASHBOARD.religious.list },
          { title: 'create', path: PATH_DASHBOARD.religious.new },
        ],
      },
      {
        title: 'Assesment',
        path: PATH_DASHBOARD.blog.root,
        icon: ICONS.assesment
      },

      {
        title: 'Doctor List',
        path: PATH_DASHBOARD.other.root,
        icon: ICONS.doctor,
      },
      {
        title: 'Medicine',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.medicine,

        children: [
          { title: 'list', path: PATH_DASHBOARD.medicine.list },
          { title: 'create', path: PATH_DASHBOARD.medicine.new },
        ],
      },

      // {
      //   title: 'Package',
      //   path: PATH_DASHBOARD.eCommerce.root,
      //   icon: ICONS.package,


      //   children: [
      //     { title: 'list', path: PATH_DASHBOARD.package.list },
      //     { title: 'create', path: PATH_DASHBOARD.package.new },
      //   ],
      // },
      /*  {
         title: 'Recommended Food',
         path: PATH_DASHBOARD.other.demoView,
         icon: ICONS.user,
       }, */
      // {
      //   title: 'Profile1',
      //   path: PATH_DASHBOARD.user.account,
      //   icon: ICONS.user,
      // },
      // {
      //   title: 'Logout',
      //   path: PATH_DASHBOARD.user.account,
      //   icon: ICONS.user,
      // },
    ],
  },
]

export default navConfig;
